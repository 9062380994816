<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    placeholder="Pilih Segment"
    style="width: 300px"
    allow-clear
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="() => (findText = null)"
    :show-arrow="true"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      default: 'multiple',
      type: String,
    },
    modelId: { type: String, default: 'id' },
  },
  emits: ['update:value'],
  setup(props) {
    const data = ref([])
    const findText = ref(null)

    const fetchData = (q = null) => {
      // data must be like data.value = [{id, name},{id, name}]
      apiClient.get('/api/vendor-types?type=<>1', { params: { q } }).then(response => {
        data.value = response.data.items
      })
    }

    onMounted(() => {
      fetchData()
    })

    const onSearch = value => {
      findText.value = value
      fetchData(value)
    }

    const highlight = value => {
      if (value === undefined) return
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }

    return { data, valueState: useVModel(props, 'value'), findText, onSearch, highlight }
  },
}
</script>

<style></style>
