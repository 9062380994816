import { ref, watch } from 'vue'


export function useDisabledDateTrans() {
const start_date_trans = ref()
const end_date_trans = ref()
const startDateTrans = ref() // string sesuai backend server yyyy-mm-dd
const endDateTrans = ref()
const firstDayMonthTrans = ref() // string awal bulan tgl 1
const lastDayMonthTrans = ref()

const disabledStartDateTrans = start_date_trans => {
  if (!start_date_trans || !end_date_trans.value) {
    return false
  }

  return start_date_trans.valueOf() > end_date_trans.value.valueOf()
}


const disabledEndDateTrans = end_date_trans => {
  if (!end_date_trans || !start_date_trans.value) {
    return false
  }

  return start_date_trans.value.valueOf() >= end_date_trans.valueOf()
}

const format = yourDate => {
    if (!yourDate) return

    const offset = yourDate.getTimezoneOffset()
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000)
    return yourDate.toISOString().split('T')[0]
  }

const getFirstDayMonthTrans = value => {
  const d = new Date(value)
  const startDatedTrans = new Date(d.getFullYear(), d.getMonth(), 1)

  return format(startDatedTrans)
}

const getLastDayMonthTrans = value => {
  const d = new Date(value)
  const dated = new Date(d.getFullYear(), d.getMonth() + 1, 0)

  return format(dated)
}



watch(start_date_trans, () => {
  // console.log('start_date', start_date.value)
  if (start_date_trans.value === null || start_date_trans.value === undefined) {
    firstDayMonthTrans.value = null
    startDateTrans.value = null
    return
  }
  const d = new Date(start_date_trans.value)
  const startDated = new Date(d.getFullYear(), d.getMonth(), 1)

  firstDayMonthTrans.value = format(startDated)
  startDateTrans.value = format(d)
})  


watch(end_date_trans, () => {
  if (end_date_trans.value === null || end_date_trans.value === undefined) {
    lastDayMonthTrans.value = null
    endDateTrans.value = null
    return
  }
  const d = new Date(end_date_trans.value)
  const date = new Date(d.getFullYear(), d.getMonth() + 1, 0)

  lastDayMonthTrans.value = format(date)
  endDateTrans.value = format(d)
})


const disabledStartDatedTrans = (current, end_date_trans) => {
  if (!current || !end_date_trans) {
    return false
  }

  return current.valueOf() > end_date_trans.valueOf()
}


const disabledEndDatedTrans = (start_date_trans, current) => {
  if (!current || !start_date_trans) {
    return false
  }

  return start_date_trans.valueOf() >= current.valueOf()
}


return {
  format,
  start_date_trans,
  end_date_trans,
  startDateTrans,
  endDateTrans,
  disabledStartDateTrans,
  disabledStartDatedTrans,
  disabledEndDatedTrans,
  disabledEndDateTrans,
  getFirstDayMonthTrans,
  getLastDayMonthTrans,
 }
}