<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row"><div class="col-lg-12"></div></div>
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="region" v-can:hide.distributor />
        <filter-province
          class="mr-2 mt-2"
          v-model:value="provinsi"
          v-model:region="region"
        />
        <filter-area
          class="mt-2"
          v-model:value="area"
          v-model:provinsi="provinsi"
          v-model:region="region"
          v-can:hide.distributor
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-kabupaten-kota
          class="mr-2 mt-2"
          v-model:value="kabupaten"
          v-model:region="region"
          v-model:provinsi="provinsi"
          v-model:area="area"
          v-can:hide.distributor
        />
        <filter-brand class=" mr-2 mt-2" v-model:value="brand" />
        <filter-distributor
          class="mr-2 mt-2"
          v-model:value="distributor"
          v-model:distributor="distributor"
          v-model:region="region"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:kabupaten="kabupaten"
          v-can:hide.distributor
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-segment
          class="mr-2 mt-2"
          :mode="null"
          style="width: 300px;"
          placeholder="Pilih Tipe Customer"
          v-model:value="segment"
        />
        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          :disabled-date="disabledStartDate"
          v-model:value="start_date"
          placeholder=" Tanggal Awal Delivery"
          format="DD MMMM YYYY"
        >
        <template #suffixIcon>
           <a-tooltip title="Tanggal Awal Delivery">
             <span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span>
            </a-tooltip>
           </template>
      </a-date-picker>

        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          :disabled-date="disabledEndDate"
          v-model:value="end_date"
          placeholder=" Tanggal Akhir Delivery"
          format="DD MMMM YYYY"
        >
        <template #suffixIcon>
           <a-tooltip title="Tanggal Akhir Delivery">
             <span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span>
            </a-tooltip>
           </template>
      </a-date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          :disabled-date="disabledStartDateTrans"
          v-model:value="start_date_trans"
          placeholder=" Tanggal Awal Transaksi"
          format="DD MMMM YYYY"
        >
          <template #suffixIcon>
           <a-tooltip title="Tanggal Awal Transaksi">
             <span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span>
            </a-tooltip>
           </template>
        </a-date-picker>

        <a-date-picker
         style="width: 300px;"
          class="mr-2 mt-2"
          v-model:value="end_date_trans"
          :disabled-date="disabledEndDateTrans"
          placeholder=" Tanggal Akhir Transaksi"
          format="DD MMMM YYYY"
        >
           <template #suffixIcon>
           <a-tooltip title="Tanggal Akhir Transaksi">
             <span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span>
            </a-tooltip>
           </template>
        </a-date-picker>

        <a-input-search
          class="mr-2 mt-2"
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
        />

        <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>

        </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="mr-3"
          title="Download Excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body mt-2">
            <div class="table-responsive ">
              <md-table
                :columns="columns"
                :data-source="data"
                size="small"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #nilai="{ text }">
                  <span>{{ text ? new Intl.NumberFormat("id-ID").format(text) : 0 }}</span>
                </template>
                <!-- <template #expandedRowRender="{index}">
                  <md-table
                    :columns="innerColumns"
                    :data-source="[data[index]]"
                    _row-key="id"
                    :pagination="false"
                  >
                  </md-table>
                </template> -->
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'

import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterBrand from '@/components/filter/FilterBrand'
import FilterSegment from '@/components/filter/FilterSegment'
import { useDisabledDate } from '@/composables/DisabledDate'
import { useDisabledDateTrans } from '@/composables/DisableDateTrans'
import qs from 'qs'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'

const acolumns = [
  {
    title: 'NO',
    slots: { customRender: 'no' },
  },
  {
    title: 'Tanggal Transaksi',
    dataIndex: 'created_at_dist',
  },
  {
    title: 'Tanggal Delivery',
    dataIndex: 'tanggal',
  },
  {
    title: 'ID Toko',
    dataIndex: 'customer_code',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'customer_name',
  },
  {
    title: 'Alamat Toko',
    dataIndex: 'customer_address',
  },
  {
    title: 'Kabupaten Toko',
    dataIndex: 'customer_kabupaten',
  },
  {
    title: 'Provinsi Toko',
    dataIndex: 'customer_propinsi',
  },
  {
    title: 'Regional Toko',
    dataIndex: 'customer_regional',
  },
  {
    title: 'Area Toko',
    dataIndex: 'customer_area',
  },

  {
    title: 'Brands',
    dataIndex: 'brand_name',
  },
  {
    title: 'Kode Produk',
    dataIndex: 'product_code',
  },
  {
    title: 'Nama Produk',
    dataIndex: 'product_name',
  },
  {
    title: 'Harga',
    dataIndex: 'price',
    slots: { customRender: 'nilai' },
  },
  {
    title: 'Zak Quantity ',
    dataIndex: 'quantity_zak',
  },
  {
    title: 'UOM 1',
    dataIndex: 'uom1',
  },
  {
    title: 'TON Quantity ',
    dataIndex: 'quantity_ton',
  },
  {
    title: 'UOM 2',
    dataIndex: 'uom2',
  },
  {
    title: 'No Transaksi',
    dataIndex: 'transaksi_code',
  },

  {
    title: 'Kode Distributor',
    dataIndex: 'distributor_code',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distributor_name',
  },
  {
    title: 'Kode Gudang ',
    dataIndex: 'warehouse_code',
  },
  {
    title: 'Nama Gudang ',
    dataIndex: 'warehouse_name',
  },
  {
    title: 'Regional Gudang',
    dataIndex: 'regional_name',
  },
  {
    title: 'Provinsi Gudang',
    dataIndex: 'warehouse_propinsi',
  },
  {
    title: 'Area Gudang',
    dataIndex: 'warehouse_area',
  },
  {
    title: 'Kabupaten Gudang',
    dataIndex: 'warehouse_kabupaten',
  },
  {
    title: 'Tipe Customer',
    dataIndex: 'type_name',
  },
  {
    title: 'Cluster',
    dataIndex: 'cluster_name',
  },
   {
    title: 'SSM',
    dataIndex: 'user_ssm',
  },
  {
    title: 'ASM',
    dataIndex: 'user_sm',
  },
  {
    title: 'TSO',
    dataIndex: 'user_am',
  },

]
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    FilterBrand,
    FilterSegment,
  },
  setup() {
    const API_URL = '/api/report/detail-trx-aksestoko'
    const route = useRoute()
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const current1 = ref(1)
    const q = ref('')
    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const product = ref([])
    const brand = ref([])
    const distributor = ref([])
    const segment = ref([])
    const { isDistributor, isHelpdesk, isAm, vendor_id, regions, areas } = useUserInfo()
    const area = ref([])
    const errorMessage = ref(null)
    const {
      start_date,
      end_date,
      startDate,
      endDate,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledDate()

    const {
      start_date_trans,
      end_date_trans,
      startDateTrans,
      endDateTrans,
      disabledStartDateTrans,
      disabledEndDateTrans,
      firstDayMonthTrans,
      lastDayMonthTrans,
    } = useDisabledDateTrans()

    const params = ref({})
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    if (isDistributor.value) {
      distributor.value.push(vendor_id.value)
    }
    if (isHelpdesk.value) {
      regions.value.forEach(item => {
        region.value.push(item.id)
      })
    }
    if (isAm.value) {
      areas.value.forEach(item => {
        area.value.push(item.id)
      })
    }

    start_date.value = moment(new Date()).startOf('month')
    end_date.value = moment(new Date())

    // start_date_trans.value = moment(new Date()).startOf('month')
    // end_date_trans.value = moment(new Date())

    columns.value = acolumns

    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        brand: brand.value,
        start_date: startDate.value,
        end_date: endDate.value,
        start_date_trans: startDateTrans.value,
        end_date_trans: endDateTrans.value,
        distributor: distributor.value,
        type: segment.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get(API_URL, {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          items.forEach((item, i) => {
            item.key = i
          })
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get(API_URL, {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${(route.meta.title_db ? route.meta.title_db : route.meta.title)
              .toLowerCase()
              .replaceAll(' ', '-')}_${moment().format('DDMMYY_HHmm')}.xlsx`,
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }
    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }
    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    return {
      q,
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      handleTableChange,
      startRow,
      region,
      provinsi,
      kabupaten,
      product,
      area,
      brand,
      segment,
      onSelectChange,
      state,
      fetchXlsx,
      fetchData,
      ...toRefs(state),
      start_date,
      end_date,
      start_date_trans,
      end_date_trans,
      search,
      errorMessage,
      disabledStartDate,
      disabledStartDateTrans,
      disabledEndDateTrans,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
      firstDayMonthTrans,
      lastDayMonthTrans,
      distributor,
      // innerColumns,
    }
  },
})
</script>
